.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

* {
  user-select: none;
}

::selection {
  background: #222;
}


a{
  color: #b1dafb;
}

body {
  height: 100%;
  overflow: hidden;width:100%;

}

#root {
  position: relative;
  width: 100%;
  height: 100%;
}

@keyframes PackRewardEntrance {
  0% {
    transform: rotate(180deg)
    translate(0)
    rotate(-180deg)
    scale(0.4);

    
  }
  25% {
    transform: rotate(360deg)
    translate(0em) 
    rotate(-360deg)
    scale(0.6);
    
  }
  100% {
    transform: rotate(360deg)
    translate(0px) 
    rotate(-360deg)
    scale(1);
    
  }
}

.pack-open-canvas {
  animation: PackRewardEntrance 1s cubic-bezier(0, 0.9, 0.44, 1.41) forwards;
}